@font-face {
  font-family: 'tripla-bw-icon';
  src: url(/082f95824a4b657949e3f3e5e0eeee47.eot);
  src:
    url(/082f95824a4b657949e3f3e5e0eeee47.eot#iefix) format('embedded-opentype'),
    url(/7e3f4943d56a8a7c76bf6b6129ac1b39.ttf) format('truetype'),
    url(/a5a0e5fd37060faa0c2c2fcc439e5524.woff) format('woff'),
    url(/tripla-bw-icon.c67f1e02df7b79c0d5d13643a7c254f8.svg#tripla-bw-icon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tripla-bw-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-visibility::before {
  content: '\e93c';
}
.icon-visibility-off::before {
  content: '\e93d';
}
.icon-triangle::before {
  content: '\e935';
}
.icon-arrow-chevron-top::before {
  content: '\e900';
}
.icon-arrow-chevron-right::before {
  content: '\e901';
}
.icon-arrow-chevron-down::before {
  content: '\e902';
}
.icon-arrow-chevron-left::before {
  content: '\e903';
}
.icon-check::before {
  content: '\e904';
}
.icon-circle::before {
  content: '\e91e';
}
.icon-delete::before {
  content: '\e905';
}
.icon-arrow-back::before {
  content: '\e906';
}
.icon-collapse::before {
  content: '\e907';
}
.icon-add-round::before {
  content: '\e908';
}
.icon-decrease-round::before {
  content: '\e909';
}
.icon-info-round::before {
  content: '\e90a';
}
.icon-add::before {
  content: '\e90b';
}
.icon-decrease::before {
  content: '\e90c';
}
.icon-check-solid::before {
  content: '\e90d';
}
.icon-cancel-solid::before {
  content: '\e90e';
}
.icon-info-solid::before {
  content: '\e90f';
}
.icon-arrow-chevron-left-round::before {
  content: '\e910';
}
.icon-warning-triangle::before {
  content: '\e911';
}
.icon-person::before {
  content: '\e912';
}
.icon-people::before {
  content: '\e913';
}
.icon-adult-companion::before {
  content: '\e914';
}
.icon-child-companion::before {
  content: '\e915';
}
.icon-apartment::before {
  content: '\e916';
}
.icon-aspect-ratio::before {
  content: '\e917';
}
.icon-bathtub::before {
  content: '\e918';
}
.icon-edit::before {
  content: '\e919';
}
.icon-business::before {
  content: '\e91a';
}
.icon-calendar::before {
  content: '\e91b';
}
.icon-download::before {
  content: '\e91c';
}
.icon-bed::before {
  content: '\e91d';
}
.icon-loading::before {
  content: '\e91f';
}
.icon-location::before {
  content: '\e920';
}
.icon-medal::before {
  content: '\e921';
}
.icon-payment::before {
  content: '\e922';
}
.icon-person-2::before {
  content: '\e923';
}
.icon-print::before {
  content: '\e924';
}
.icon-star::before {
  content: '\e925';
}
.icon-today::before {
  content: '\e926';
}
.icon-toilet::before {
  content: '\e927';
}
.icon-wifi::before {
  content: '\e928';
}
.icon-baseline-broken::before {
  content: '\e929';
}
.icon-bathtub-2::before {
  content: '\e92a';
}
.icon-child-care::before {
  content: '\e92b';
}
.icon-coupon::before {
  content: '\e92c';
}
.icon-credit-card-regular::before {
  content: '\e92d';
}
.icon-edit-note::before {
  content: '\e92e';
}
.icon-language::before {
  content: '\e92f';
}
.icon-membership-tag::before {
  content: '\e930';
}
.icon-no-result::before {
  content: '\e931';
}
.icon-smoking::before {
  content: '\e932';
}
.icon-non-smoking::before {
  content: '\e933';
}
.icon-pdf::before {
  content: '\e934';
}
.icon-qrcode::before {
  content: '\e936';
}
.icon-room::before {
  content: '\e937';
}
.icon-schedule::before {
  content: '\e938';
}
.icon-shower::before {
  content: '\e939';
}
.icon-sort::before {
  content: '\e93a';
}
.icon-tune::before {
  content: '\e93b';
}
